import React, { useContext } from "react";
import { useState, useCallback, useEffect } from "react";

// ----------------------------------------------------------------
//     データ構成
// ----------------------------------------------------------------

/**
 * ローカルストレージでのダークモード設定です。
 *
 * ダークモードは `localStorage` を使って管理しています。3種類のモードがあります。
 *
 * - "light" … 通常
 * - "dark" … ダークモード
 * - "undefined" … OS の設定を見る
 *
 * `undefined` の場合は未設定、設定している場合は `boolean` です。
 */
type AppDarkMode = boolean | undefined;

/**
 * 現在のモードを管理するための Context です。
 *
 * Gatsby と Material-UI の仕様上、ここで管理されている状態はHTMLを読み込だ後、
 * 非同期で初期化されます。そのため、HTMLを開いた時に一瞬デフォルトの light
 * モードで表示されてしまいます。
 *
 * その対策として、この Context が初期化されるまでは、同期実行される<script>タグ
 * と CSS を自前で定義する事で違和感なく表示させます。
 *
 * つまり、ダークモード設定は以下のようにタイミング次第で管理方法が変わります。
 *
 * - 読み込み直後の一瞬: `<body>` の `helpers-initial-mode-*` クラス
 * - その後: `ModeContext`
 * - JSサポートしない場合: light モード決め打ち
 *
 * 煩雑になってまでもこの仕組みを採用したのは、ただのこだわりです…。
 * また、Material-UI の今後のサポートによってはこの仕組みが不要になるかもしれません。
 *
 * 参考: `setInitialMode`, `initModeContext`
 */
const ModeContext = React.createContext({
  systemDarkMode: false,
  setSystemDarkMode: (_dark: boolean): void => {
    throw Error();
  },
  appDarkMode: undefined as AppDarkMode,
  setAppDarkMode: (_dark: AppDarkMode): void => {
    throw Error();
  },
});

/**
 * 初期状態で簡易的にモードを設定します。
 *
 * この処理内では、`<body>` にクラスを追加する事でモードを設定します。
 * このモード設定は `initModeContext` を呼び出した段階でクリアされます。
 */
const setInitialMode = `(function() {
  let m =
    localStorage && localStorage.getItem && localStorage.getItem("2022/theme");
  if (!m) {
    m = (matchMedia && matchMedia("(prefers-color-scheme: dark)")).matches
      ? "dark"
      : "light";
  }
  if (!m) return;
  document.body.classList.add("helpers-initial-mode-" + m);
})();`;

/**
 * `ModeContext` に現在のモードを設定します。
 */
const initModeContext = ({
  setSystemDarkMode,
  setAppDarkMode,
}: {
  setSystemDarkMode: (dark: boolean) => void;
  setAppDarkMode: (dark: AppDarkMode) => void;
}): void => {
  document.body.classList.remove("helpers-initial-mode-dark");
  document.body.classList.remove("helpers-initial-mode-light");
  setSystemDarkMode(window ? matchDarkModeMedia(window).matches : false);
  setAppDarkMode(window && loadAppDarkMode(window));
};

// ----------------------------------------------------------------
//     公開メソッド
// ----------------------------------------------------------------

/**
 * ブラウザの情報を元に必要な設定を取得します。
 */
export const initMode = (win?: Window): void => {
  if (!win) return;
  window = win;
};

/**
 * モードを設定管理します。
 */
export const ModeProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [systemDarkMode, setSystemDarkMode] = useState(false);
  const [appDarkMode, setAppDarkMode] = useState(undefined as AppDarkMode);
  useEffect(() => initModeContext({ setSystemDarkMode, setAppDarkMode }), []);

  return (
    <ModeContext.Provider
      value={{ systemDarkMode, setSystemDarkMode, appDarkMode, setAppDarkMode }}
    >
      <script
        dangerouslySetInnerHTML={{
          __html: setInitialMode,
        }}
      />
      {children}
    </ModeContext.Provider>
  );
};

/**
 * テーマの取得と切り替えを行います。
 */
export const useDarkMode = (): [boolean, (dark: boolean) => void] => {
  const { systemDarkMode, setSystemDarkMode, appDarkMode, setAppDarkMode } =
    useContext(ModeContext);

  useEffect(() => {
    if (!window) return;

    const m = matchDarkModeMedia(window);
    const f = (e: { matches: boolean }): void => {
      setSystemDarkMode(e.matches);
    };

    m.addListener(f);
    return () => {
      m.removeListener(f);
    };
  }, [setSystemDarkMode]);

  const setDark = useCallback(
    (dark: boolean) => {
      const appDarkMode = dark === systemDarkMode ? undefined : dark;
      setAppDarkMode(appDarkMode);
      if (window) saveAppDarkMode(window, appDarkMode);
    },
    [systemDarkMode, setAppDarkMode]
  );

  const dark = appDarkMode !== undefined ? appDarkMode : systemDarkMode;
  return [dark, setDark];
};

// ----------------------------------------------------------------
//     その他
// ----------------------------------------------------------------

/**
 * OS 設定がダークモードかどうかを判別する media query を返します。
 */
const matchDarkModeMedia = (window: Window): MediaQueryList =>
  window.matchMedia("(prefers-color-scheme: dark)");

/**
 * localStorage の設定がダークモードかどうかを返します。
 */
const loadAppDarkMode = (window: Window): AppDarkMode => {
  const m = window.localStorage.getItem("2022/theme");
  return m !== null ? m === "dark" : undefined;
};

/**
 * localStorage の設定がダークモードを設定します。
 */
const saveAppDarkMode = (window: Window, appDarkMode: AppDarkMode): void => {
  const { localStorage } = window;
  if (appDarkMode === undefined) {
    localStorage.removeItem("2022/theme");
  } else {
    localStorage.setItem("2022/theme", appDarkMode ? "dark" : "light");
  }
};

/**
 * ブラウザ上では `window`、サイト生成時は `undefined` を持ちます
 *
 * ブラウザでは、`iniwMode()` を使って初期化されます。
 */
let window: Window | undefined;
