import { Box } from "@mui/material";
import React from "react";

export const Section: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <Box
      mt={3}
      py={{ xs: 3, sm: 5 }}
      mx={{ xs: 2, sm: 0 }}
      mb={{ xs: 3, sm: 0 }}
      {...props}
    />
  );
};
