import React from "react";
import logo from "../../../images/top/logo.svg";
import MainContainer from "helpers/MainContainer";
import { Box } from "@mui/material";

type TopSectionProps = {
  children?: React.ReactNode;
};
const TopSection: React.FC<TopSectionProps> = ({ children }) => {
  return (
    <Box mb={{ xs: 8, sm: 24 }} mx={2}>
      <MainContainer>
        <Box
          mt={{ xs: 8, sm: 22 }}
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${logo})`,
            backgroundSize: "contain",
          }}
          height={{ xs: "58px", sm: "116px" }}
        />
        {children}
      </MainContainer>
    </Box>
  );
};

export default TopSection;
