/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const { initMode } = require("./src/helpers/mode");

exports.onClientEntry = () => {
  initMode(window);
};

exports.shouldUpdateScroll = ({
  routerProps: { location },
  // eslint-disable-next-line no-unused-vars
  getSavedScrollPosition,
}) => {
  const { pathname } = location;
  // セッションを表示する時だけスクロールをしない。
  if (pathname.match(/timetable\/\d+\/?$/)) {
    return false;
  }

  return true;
};
