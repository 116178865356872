import React from "react";
import { withPrefix } from "gatsby";
import { H5En } from "helpers/text";
import { ExternalLink } from "helpers/Link";
import { Box, Paper } from "@mui/material";

// ----------------------------------------------------------------
//     各種コンポーネント
// ----------------------------------------------------------------

const SponsorTitle: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box mx={2} mt={{ xs: 3.5, sm: 6 }} mb={{ xs: 1.5, sm: 3 }}>
      <H5En singleLine xsAlignLeft align="center">
        {children}
      </H5En>
    </Box>
  );
};

const LogoList: React.FC<{ width: number; children?: React.ReactNode }> = ({
  width,
  children,
}) => {
  return (
    <Box
      sx={{
        margin: "0 auto",
        display: { xs: "flex", sm: null },
        justifyContent: { xs: "center", sm: null },
        flexWrap: { xs: "wrap", sm: null },
        maxWidth: width,
      }}
    >
      {children}
    </Box>
  );
};

const Logo: React.FC<{
  href: string;
  image: string;
  width: number;
  small?: boolean;
}> = ({ href, image, width, small }) => {
  return (
    <Box
      component={ExternalLink}
      m={{ xs: small ? 1 : 1.5, sm: 1.5 }}
      href={href}
    >
      <Paper
        sx={{
          backgroundSize: "contain",
          backgroundPosition: "center",
          m: { xs: "0 auto", sm: null },
          width: `${width}px`,
          height: `${width / 2}px`,
          backgroundImage: `url(${withPrefix(image)})`,
        }}
      />
    </Box>
  );
};

// ----------------------------------------------------------------
//     全体構成
// ----------------------------------------------------------------

export const Sponsors: React.FC = () => {
  const allSponsors = useSponsors();
  const sponsors = allSponsors.map((s) => ({
    plan: s.plan,
    key: s.companyName,
    logo: {
      href: s.companyUrl,
      image: s.companyLogoUrl,
    },
  }));

  return (
    <>
      <SponsorTitle>PLATINUM SPONSORS</SponsorTitle>
      <LogoList width={(300 + 24) * 2}>
        {sponsors
          .filter((s) => s.plan === "PLATINUM")
          .map((s) => (
            <Logo key={s.key} {...s.logo} width={300} />
          ))}
      </LogoList>
      <SponsorTitle>GOLD SPONSORS</SponsorTitle>
      <LogoList width={(250 + 24) * 3}>
        {sponsors
          .filter((s) => s.plan === "GOLD")
          .map((s) => (
            <Logo key={s.key} {...s.logo} width={250} />
          ))}
      </LogoList>
      <SponsorTitle>SUPPORTER</SponsorTitle>
      <LogoList width={(160 + 24) * 4}>
        {sponsors
          .filter((s) => s.plan === "SUPPORTER")
          .map((s) => (
            <Logo key={s.key} {...s.logo} small width={160} />
          ))}
      </LogoList>
    </>
  );
};

// ----------------------------------------------------------------
//     データ取得
// ----------------------------------------------------------------

type Plans = "PLATINUM" | "GOLD" | "SUPPORTER" | "COMMITTEE_SUPPORT";

interface Info {
  id: string;
  plan: Plans;
  companyUrl: string;
  companyName: string;
  companyLogoUrl: string;
  sort: number;
}

const useSponsors = (): Info[] => {
  return sponsorValues.map(([plan, name, url, image], priority) => ({
    id: `${priority}`,
    plan:
      plan === "プラチナ"
        ? "PLATINUM"
        : plan === "ゴールド"
        ? "GOLD"
        : "SUPPORTER",
    companyUrl: url,
    companyName: name,
    companyLogoUrl: `/images/sponsors/${image}`,
    sort: priority,
  }));
};

// prettier-ignore
const sponsorValues = [
  ["プラチナ","株式会社Mobility Technologies","https://mo-t.com/", "mobilitytechnologies.png"],
  ["プラチナ","メドピア株式会社","https://medpeer.co.jp/", "medpeer.png"],
  ["プラチナ","株式会社ゆめみ","https://www.yumemi.co.jp/", "yumemi.png"],
  ["ゴールド","Google合同会社","https://android-developers-jp.googleblog.com/", "google.png"],
  ["ゴールド","株式会社ディー・エヌ・エー","https://dena.com", "dena.png"],
  ["ゴールド","株式会社リクルート","https://www.recruit.co.jp/", "recruit.png"],
  ["ゴールド","株式会社サイバーエージェント","https://www.cyberagent.co.jp/", "cyberagent.png"],
  ["ゴールド","LINE株式会社","https://engineering.linecorp.com/ja/", "line.png"],
  ["ゴールド","株式会社マネーフォワード","https://corp.moneyforward.com/", "moneyforward.png"],
  ["ゴールド","株式会社ZOZO","https://corp.zozo.com/", "zozo.png"],
  ["ゴールド","株式会社アンドパッド","https://andpad.co.jp/", "andpad.png"],
  ["ゴールド","株式会社メルカリ","https://engineering.mercari.com/?utm_source=droidkaigi&utm_medium=sponsor-page&utm_campaign=droidkaigi2022", "mercari.png"],
  ["ゴールド","合同会社DMM.com","https://dmm-corp.com/", "dmm.png"],
  ["ゴールド","株式会社ミクシィ","https://mixigroup-recruit.mixi.co.jp/", "mixi.png"],
  ["ゴールド","サイボウズ株式会社","https://tech.cybozu.io/", "cybozu.png"],
  ["ゴールド","フェンリル株式会社","https://www.fenrir-inc.com/", "fenrir.png"],
  ["ゴールド","クックパッド株式会社","https://info.cookpad.com/careers/", "cookpad.png"],
  ["ゴールド","楽天グループ株式会社","https://commerce-engineer.rakuten.careers/", "rakuten.png"],
  ["ゴールド","ウォンテッドリー株式会社","https://docs.wantedly.dev/", "wantedly.png"],
  ["ゴールド","株式会社U-NEXT","https://www.unext.co.jp/", "u-next.png"],
  ["ゴールド","株式会社サーキュレーション","https://flxy.jp/", "flexy.png"],
  ["ゴールド","LINE Fukuoka株式会社","https://linefukuoka.co.jp/ja/career/", "linefukuoka.png"],
  ["ゴールド","株式会社ガラパゴス","https://www.glpgs.com/service/appdev/", "galapagos.png"],
  ["ゴールド","LINE Digital Frontier株式会社","https://ldfcorp.com/ja", "linedigitalfrontier.png"],
  ["ゴールド","チームラボ株式会社","https://www.team-lab.com/recruit/", "teamlabo.png"],
  ["サポーター","株式会社メドレー","https://www.medley.jp/", "medley.png"],
  ["サポーター","ヤフー株式会社","https://developer.yahoo.co.jp/", "yahoojapan.png"],
  ["サポーター","株式会社ヤプリ","https://yappli.co.jp/", "yappli.png"],
  ["サポーター","日本経済新聞社","https://hack.nikkei.com/", "nikkei.png"],
  ["サポーター","ピクシブ株式会社","https://www.pixiv.co.jp/", "pixiv.png"],
  ["サポーター","and factory株式会社","https://andfactory.co.jp/", "andfactory.png"],
  ["サポーター","Classi株式会社","https://corp.classi.jp/careers/", "classi.png"],
  ["サポーター","コネヒト株式会社","https://connehito.com/", "connehito.png"],
  ["サポーター","エムスリー株式会社","https://jobs.m3.com/engineer/", "m3.png"],
  ["サポーター","株式会社はてな","https://hatena.co.jp/", "hatena.png"],
  ["サポーター","フラー株式会社","https://www.fuller-inc.com/", "fuller.png"],
  ["サポーター","Hamee株式会社","https://hamee.co.jp/", "hamee.png"],
  ["サポーター","スタディプラス株式会社","https://info.studyplus.co.jp/", "studyplus.png"],
  ["サポーター","株式会社フォトラクション","https://corporate.photoruction.com/", "photoruction.png"],
  ["サポーター","コインチェック株式会社","https://corporate.coincheck.com/recruit/", "coincheck.png"],
  ["サポーター","Chatwork株式会社","https://recruit.chatwork.com/", "chatwork.png"],
  ["サポーター","REALITY株式会社","https://reality.inc/", "reality.png"],
  ["サポーター","東急株式会社","https://10q89s.jp/", "tokyu.png"],
  ["サポーター","株式会社ビットキー","https://bitkey.co.jp/", "bitkey.png"],
  ["サポーター","株式会社RevComm","https://www.revcomm.co.jp/careers/", "revcomm.png"],
  ["サポーター","株式会社RABO","https://rabo.cat", "rabo.png"],
  ["サポーター","株式会社ドワンゴ","https://dwango.co.jp/", "dwango.png"],
  ["サポーター","株式会社アイキューブドシステムズ","https://www.i3-systems.com/", "icubedsystems.png"],
  ["サポーター","オイシックス・ラ・大地株式会社","https://recruit.oisixradaichi.co.jp/", "oisix.png"],
  ["サポーター","株式会社ミラティブ","https://www.mirrativ.co.jp/", "mirrativ.png"],
  ["サポーター","ヘイ株式会社","https://hello.hey.jp/engineer", "stores.png"],
  ["サポーター","ディップ株式会社","https://www.dip-net.co.jp/", "dip.png"],
  ["サポーター","株式会社ココナラ","https://coconala.co.jp/", "coconara.png"],
  ["サポーター","株式会社デプロイゲート","https://deploygate.com/", "deploygate.png"],
  ["サポーター","からくり株式会社","https://caraquri.com/", "caraquri.png"],
] as const;
