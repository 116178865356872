import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import { BlockClass } from "./Models";

const minCellWidth = 170;
const maxColumns = 7;
export const tableWidth = minCellWidth * maxColumns + 100;
const cellWidth = (columns: number): number => (tableWidth - 100) / columns;

export const TableStyle: SxProps<Theme> = ({ breakpoints, palette }) => ({
  borderCollapse: "collapse",
  [breakpoints.only("xs")]: {
    width: "100%",
    "& th, & td": {
      display: "block",
    },
  },

  "&>*>*>th": {
    color: palette.text.disabled,
    typography: "subtitle1",
  },

  "&>thead>tr>th": {
    padding: "16px 0",
    textAlign: "center",

    [breakpoints.only("xs")]: {
      display: "none",
    },
  },

  "&>tbody>tr>th": {
    width: "68px",
    paddingTop: "8px",
    paddingRight: "16px",
    textAlign: "left",
    verticalAlign: "top !important",

    [breakpoints.only("xs")]: {
      // fontSize: ${props => props.theme.fonts.large};
      paddingTop: "24px",
      paddingBottom: "8px",
    },
  },

  "&>*>*>td": {
    width: cellWidth(5),
    [breakpoints.only("xs")]: {
      width: "auto",
    },
  },
});
export const TableTdStyle: (height: number) => SxProps<Theme> =
  (height) =>
  ({ breakpoints, spacing }) => ({
    padding: "8px 4px",
    verticalAlign: "top",
    borderLeft: "1px solid transparent",

    [breakpoints.only("xs")]: {
      width: "100%",
      borderLeft: "none",
      padding: "4px 0px",
    },
    [breakpoints.up("sm")]: {
      height: spacing(30 * height),
    },
  });
export const TableTrStyle: (cls: BlockClass) => SxProps<Theme> = ({
  height: length,
  priority,
}) => {
  return ({ breakpoints, palette, spacing }) => ({
    height: spacing(30 * length),
    borderTop: `1px solid ${palette.timetableBorder}`,

    [breakpoints.only("xs")]: {
      height: "auto",
      borderTop: "none",
    },

    "&>th": {
      height: spacing(30 * length),
      [breakpoints.only("xs")]: {
        height: "auto",
      },
      [breakpoints.up("sm")]: {
        typography: priority === "minor" ? "subtitle2" : undefined,
      },
    },
  });
};
export const TableBreakStyle: SxProps<Theme> = ({ breakpoints }) => ({
  height: "150px",
  textAlign: "center",
  verticalAlign: "middle",

  [breakpoints.only("xs")]: {
    height: "auto",
  },
});

export const TabletdNoneIfMobileStyle: SxProps<Theme> = ({ breakpoints }) => ({
  tdNoneIfMobile: {
    [breakpoints.only("xs")]: {
      display: "none !important",
    },
  },
});
