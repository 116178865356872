import React from "react";
import { Body, Subheadline } from "helpers/text";
import { Grid } from "@mui/material";

const NewsItem: React.FC<{ date: string; text: React.ReactNode }> = ({
  date,
  text,
}) => {
  return (
    <Grid
      container
      alignItems="baseline"
      sx={{
        marginTop: 2,
        sm: {
          marginTop: 3,
        },
      }}
    >
      <Grid item xs={12} sm="auto" style={{ minWidth: 140 }}>
        <Subheadline>{date}</Subheadline>
      </Grid>
      <Grid item xs={12} sm>
        <Body
          sx={{
            "& a": {
              color: "primary.main",
            },
          }}
        >
          {text}
        </Body>
      </Grid>
    </Grid>
  );
};

export default NewsItem;
