import { Box, Paper } from "@mui/material";
import React from "react";

export const CfsCard: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Paper
      sx={(theme) => ({
        overflow: "hidden",
        ...theme.typography.caption,
        color: "text.secondary",
        "& h6": {
          ...theme.typography.body2,
          color: "text.primary",
          lineHeight: 1,
          marginTop: 2,
          marginBottom: 1,
        },
        "& ul": {
          listStylePosition: "outside",
          paddingLeft: 2,
          margin: "0",
        },
      })}
    >
      <Box margin={2}>{children}</Box>
    </Paper>
  );
};
