import React from "react";
import { main, Layout } from "helpers/MainLayout";
import MainContainer from "helpers/MainContainer";
import { Body, Caption, Title, Subtitle } from "helpers/text";
import { Section } from "helpers/pages/index/common";
import TopSection from "helpers/pages/index/TopSection";
import NewsItem from "helpers/pages/index/NewsItem";
import { makeText } from "helpers/i18n";
import { Sponsors } from "helpers/pages/index/Sponsors";
import Link, { ExternalLink } from "helpers/Link";
import whatIsDroidKaigi from "../images/top/what_is_droidkaigi.svg";
import { Box, Button, Grid } from "@mui/material";
import { GlobalThemeProviderHead } from "helpers/theme";
import Metadata from "helpers/MainLayout/Metadata";

// ----------------------------------------------------------------
//     テキスト
// ----------------------------------------------------------------

const useText = makeText({
  ja: {
    topText:
      "DroidKaigi 2022 は終了しました。セッション動画・スライドはタイムテーブルから確認できます。",
    topLink: "https://www.youtube.com/c/DroidKaigi/playlists",
    topLinkText: "YouTube から動画を見る",
    topLinkText2: "タイムテーブルからスライド・動画を探す",
    scholarshipText:
      "学生の方に向けてスカラーシッププログラムを用意しています。学生の方は是非ご利用ください。",
    scholarshipButton: "スカラーシッププログラムに申し込む",
    about: (
      <React.Fragment>
        DroidKaigiはエンジニアが主役のAndroidカンファレンスです。
        <br />
        Android技術情報の共有とコミュニケーションを目的に、2022年10月5日(水)〜7日(金)の3日間開催します。
      </React.Fragment>
    ),
    detail: {
      dateTitle: "日時",
      dateValue: "2022年10月5日 (水)〜10月7日(金)",
      placeTitle: "場所",
      placeValue: (
        <React.Fragment>
          <b>オフライン会場</b> <br />
          東京ドームシティ プリズムホール (Day 1, Day 2) <br />
          ベルサール飯田橋ファースト (Day 3)
        </React.Fragment>
      ),
      access: (
        <React.Fragment>
          会場へのアクセスについては「
          <Box
            component={ExternalLink}
            href="https://medium.com/droidkaigi/%E3%82%8F%E3%81%8B%E3%82%8B-droidkaigi-2022%E3%81%AE%E4%BC%9A%E5%A0%B4%E3%81%B8%E3%81%AE%E8%A1%8C%E3%81%8D%E6%96%B9-f29ea5527de5"
            color="primary.main"
          >
            わかる！DroidKaigi 2022の会場への行き方
          </Box>
          」をご覧ください。
        </React.Fragment>
      ),
      feeTitle: "参加費",
      online: "オンライン視聴",
      feeValue: (
        <React.Fragment>
          <b>オフライン会場参加</b>
          <br />
          一般価格: 15,800円 <br />
          アーリーバード: 14,200円 <br />
          スーパーアーリーバード: 12,600円 <br />
          <br />
          <b>オンライン視聴</b> <br />
          参加登録: 無料（先着500名にTシャツつき）
        </React.Fragment>
      ),
    },
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3239.848865569918!2d139.7519632!3d35.7053366!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c477c2b1661%3A0x293e44db0d9ffd1c!2z44CSMTEyLTAwMDQg5p2x5Lqs6YO95paH5Lqs5Yy65b6M5qW977yR5LiB55uu77yT4oiS77yW77yR!5e0!3m2!1sja!2sjp!4v1661076178601!5m2!1sja!2sjp",
    news: [
      {
        date: "2022.09.07",
        text: (
          <ExternalLink href="https://droidkaigi.doorkeeper.jp/events/141969">
            チケット販売を開始しました
          </ExternalLink>
        ),
      },
    ],
    schedule: {
      ticketSales: { title: "チケット販売開始", date: "2022.09.07" },
      droidKaigi: { title: "DroidKaigi 開催", date: "2022.10.05" },
    },
  },
  en: {
    topText:
      "DroidKaigi 2022 is over. Videos and slides are available from timetable.",
    topLink: "https://www.youtube.com/c/DroidKaigi/playlists",
    topLinkText: "Watch videos from YouTube",
    topLinkText2: "Find slides/videos from Timetable",
    scholarshipText:
      "If you're a student, you can apply for our scholarship program!",
    scholarshipButton: "Apply for the Scholarship Program",
    about: (
      <React.Fragment>
        DroidKaigi is a conference tailored for Android developers.
        <br />
        It&apos;s scheduled to take place on the 5 to 7 of October 2022.
      </React.Fragment>
    ),
    detail: {
      dateTitle: "Dates",
      dateValue: "5 - 7 October, 2022",
      placeTitle: "Place",
      placeValue: (
        <React.Fragment>
          <b>In-Person Event</b> <br />
          Tokyo Dome City PRISM HALL (Day 1, Day 2) <br />
          Bellesalle Iidabashi First (Day 3)
        </React.Fragment>
      ),
      access: (
        <React.Fragment>
          See “
          <Box
            component={ExternalLink}
            href="https://medium.com/droidkaigi/how-to-get-to-the-droidkaigi-2022-in-person-venues-2fd61160b9fd"
            color="primary.main"
          >
            How to get to the DroidKaigi 2022 in-person venues
          </Box>
          ” for more details
        </React.Fragment>
      ),
      online: "Livestream",
      feeTitle: "Fee",
      feeValue: (
        <React.Fragment>
          <b>In-Person Event</b>
          <br />
          Regular: 15,800 JPY <br />
          Early Bird: 14,200 JPY <br />
          Super Early Bird: 12,600 JPY <br />
          <br />
          <b>Livestream</b> <br />
          Registration: Free (T-shirt giveaway for first 500 registrations)
        </React.Fragment>
      ),
    },
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3239.848865569918!2d139.7519632!3d35.7053366!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c477c2b1661%3A0x293e44db0d9ffd1c!2z44CSMTEyLTAwMDQg5p2x5Lqs6YO95paH5Lqs5Yy65b6M5qW977yR5LiB55uu77yT4oiS77yW77yR!5e0!3m2!1sja!2sjp!4v1661076178601!5m2!1sja!2sjp",
    news: [
      {
        date: "2022.09.07",
        text: (
          <ExternalLink href="https://droidkaigi.doorkeeper.jp/events/141969">
            Ticket sales has started
          </ExternalLink>
        ),
      },
    ],
    schedule: {
      ticketSales: { title: "Ticket Sales start", date: "7 September 2022" },
      droidKaigi: { title: "DroidKaigi", date: "5 - 7 October 2022" },
    },
  },
});

// ----------------------------------------------------------------
//     全体構成
// ----------------------------------------------------------------

const IndexMain: React.FC = () => {
  const text = useText();
  return (
    <Layout>
      <TopSection>
        <Body mx="auto" width={{ sm: 400 }} textAlign="center" mt={8}>
          {text.topText}
        </Body>
        <Grid container spacing={2} mt={2} justifyContent="center">
          <Grid item xs={12} sm={6} md={5}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/timetable"
              fullWidth
            >
              {text.topLinkText2}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Button
              variant="contained"
              color="primary"
              component={ExternalLink}
              href={text.topLink}
              fullWidth
            >
              {text.topLinkText}
            </Button>
          </Grid>
        </Grid>
      </TopSection>
      <Section>
        <MainContainer>
          <Title>What is DroidKaigi?</Title>
          <Body align="center" xsAlignLeft>
            {text.about}
          </Body>
          <Box
            mt={{ xs: 4, sm: 7 }}
            mb={{ sm: 4 }}
            pt={{ xs: "30%", sm: "187px" }}
            style={{
              background: `url(${whatIsDroidKaigi}) no-repeat center`,
              backgroundSize: "contain",
            }}
          />
        </MainContainer>
      </Section>
      <Section>
        <MainContainer>
          {/* グリッドで余計な margin が発生するので marginBottomOffset で調整 */}
          <Title marginBottomOffset={-2}>Overview</Title>
          <Grid container>
            <Grid item sm={5}>
              <Subtitle>{text.detail.dateTitle}</Subtitle>
              <Body>{text.detail.dateValue}</Body>
              <Subtitle>{text.detail.feeTitle}</Subtitle>
              <Body>{text.detail.feeValue}</Body>
            </Grid>
            <Grid item sm={1} />
            <Grid item sm={5}>
              <Subtitle>{text.detail.placeTitle}</Subtitle>
              <Body>{text.detail.placeValue}</Body>
              <Caption color="textSecondary">{text.detail.access}</Caption>
              <br />
              <br />
              <Body>
                <b>{text.detail.online}</b>
                <br />
                YouTube
              </Body>
            </Grid>
          </Grid>
        </MainContainer>
      </Section>
      <iframe
        src={text.mapUrl}
        width="100%"
        height="500"
        frameBorder="0"
        style={{ border: 0 }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        allowFullScreen
      ></iframe>
      <Section>
        <MainContainer>
          <Title>News</Title>
          {text.news.map(({ date, text }) => (
            <NewsItem key={date} date={date} text={text} />
          ))}
        </MainContainer>
      </Section>
      <Section>
        <MainContainer>
          <Title>Sponsors</Title>
          <Sponsors />
        </MainContainer>
      </Section>
    </Layout>
  );
};

export default main(IndexMain);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Head: React.FC<{ pageContext: any }> = ({ pageContext }) => {
  const { title, description } = pageContext;

  return (
    <>
      <Metadata title={title} description={description} />
      <GlobalThemeProviderHead />
    </>
  );
};
