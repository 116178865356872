import React, { useCallback } from "react";
import { TimetableData, useModels, TimetableCell } from "./Models";
import SessionCard from "./SessionCard";
import {
  TableBreakStyle,
  TableStyle,
  TabletdNoneIfMobileStyle,
  TableTdStyle,
  TableTrStyle,
  tableWidth,
} from "./Table";
// import TitledPage, { pagePaddings } from "./TitledPage";
import { useLang, resolve } from "helpers/i18n";
import { navigate } from "gatsby";
import { DetailDialog } from "./DetailDialog";
import { Headline } from "helpers/text";
import { Box } from "@mui/material";

// ----------------------------------------------------------------
//     レイアウト/トップレベル
// ----------------------------------------------------------------

const Root: React.FC<{ sessionId: string }> = ({ sessionId }) => {
  return (
    <Box
      sx={{
        // カードをタップした時なども含め、デフォルトではとにかくリンクに下線を表示しない。
        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
      }}
    >
      <div className={"classNames.TitledPage"}>
        <Box
          component="ul"
          sx={{
            listStyle: "none",
            textAlign: "center",
            color: "text.primary",
            // fontSize: ${props => props.theme.fonts.midium};
            paddingBottom: "1.2rem",
            "& li": {
              display: "inline",
              padding: "16px",
            },
          }}
        >
          <li>
            <a href="#day1">DAY.01</a>
          </li>
          <li>
            <a href="#day2">DAY.02</a>
          </li>
          <li>
            <a href="#day3">DAY.03</a>
          </li>
        </Box>
      </div>
      <div style={{ width: "100%" }}>
        <Contents />
      </div>
      <Dialog sessionId={sessionId} />
    </Box>
  );
};
export default Root;

const DayX: React.FC<{
  headerId: string;
  headerTitle: string;
  headerContent: string;
  children?: React.ReactNode;
}> = ({ headerId, headerTitle, headerContent, children }) => {
  return (
    <React.Fragment>
      <Box
        width={{ xs: "auto", sm: tableWidth }}
        mx="auto"
        px={{ xs: 2, sm: 5 }}
        pb={{ xs: 6, sm: 6 }}
      >
        <Box
          component="h2"
          id={headerId}
          title={headerTitle}
          padding={{ xs: "1.2rem 0", sm: "2.4rem 0" }}
          textAlign="center"
          color="primary.main"
        >
          {headerContent}
        </Box>
        {children}
      </Box>
    </React.Fragment>
  );
};

const Contents: React.FC = () => {
  const { day1, day2, day3 } = useModels();

  return (
    <React.Fragment>
      <DayX
        headerId="day1"
        headerTitle="2022/10/05"
        headerContent="DAY.01 (Oct&nbsp;5th,&nbsp;2022)"
      >
        <Timetable data={new TimetableData(day1.sessions, day1.rooms)} />
      </DayX>

      <DayX
        headerId="day2"
        headerTitle="2022/10/06"
        headerContent="DAY.02 (Oct&nbsp;6th,&nbsp;2022)"
      >
        <Timetable data={new TimetableData(day2.sessions, day2.rooms)} />
      </DayX>
      <DayX
        headerId="day3"
        headerTitle="2022/10/07"
        headerContent="DAY.03 (Oct&nbsp;7th,&nbsp;2022)"
      >
        <Timetable data={new TimetableData(day3.sessions, day3.rooms)} />
      </DayX>
    </React.Fragment>
  );
};

// ----------------------------------------------------------------
//     レイアウト/ダイアログ描画
// ----------------------------------------------------------------

/**
 * タイムテーブルのセッションをクリックしたのかどうかを管理します。
 * この変数はセッションをクリックしたタイミングで true になり、
 * その後セッションのダイアログが閉じたタイミングで false になります。
 *
 * グローバル変数にしているのは、history の変化を超えて管理できるスコープが他に思いつかなかったためです。
 * state で管理できるのであればそちらの方が望ましいと思っています。
 */
let sessionClicked = false;

const Dialog: React.FC<{ sessionId: string }> = ({ sessionId }) => {
  const lang = useLang();
  const onExit = useCallback(() => {
    // const { history, location } = this.props;
    // タイムテーブルを開きますが、ダイアログの開き方によって少しだけ動作が変わります。
    //
    // - タイムテーブルからダイアログが開かれた場合 … ブラウザの履歴を戻ります。
    // - その他の場合(URL直で開かれた場合など) … 通常の遷移をします。
    if (sessionClicked) {
      window.history.back();
    } else {
      navigate(resolve(lang, "/timetable"));
    }
  }, [lang]);
  const { sessions } = useModels();

  if (!sessionId) {
    sessionClicked = false;
    return <DetailDialog sessionizeSession={undefined} />;
  }

  const dialogSession =
    (sessionId && sessions.find((s) => s.id === sessionId)) || undefined;

  if (sessionClicked) {
    // React Static の autoScrollTop を無効化するためのハック
    // これが無いと戻った時に画面のトップにまでスクロールしてしまいます。
    //
    // この処理はタイムテーブルのセッションをクリックした時だけ有効になります。
    // URLから直接開いた時には有効にはなりません。
    // また、本番の時だけ __noScrollTo の更新タイミングが異なるため、
    // 開発の時も両方とも動作させるために、setTimeoutを挟んでいます。
    //
    // 参考: https://github.com/nozzle/react-static/blob/v5.9.12/src/client/components/RouterScroller.js#L15
    // setTimeout(() => {
    //   if (window.__noScrollTo === false) {
    //     window.__noScrollTo = true;
    //   } else if (window.__noScrollTo !== true) {
    // React Static 以外を使っている場合などはこのハックは無効なので、
    // とりあえず警告だけ出しておきます。
    console.warn(
      "timetable: window.__noScrollTo is undefined or the value is unexpected."
    );
    //   }
    // }, 200);
  }

  return <DetailDialog sessionizeSession={dialogSession} onExit={onExit} />;
};

// ----------------------------------------------------------------
//     レイアウト/タイムテーブル描画
// ----------------------------------------------------------------

const Timetable: React.FC<{ data: TimetableData }> = ({ data }) => {
  return (
    <Box component="table" sx={TableStyle}>
      <thead>
        <tr>
          <th />
          {data.rooms.names.map((n) => (
            <th key={n}>{n.toUpperCase()}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.rows.map(({ time, sessions, blockClass }) => (
          <Box component="tr" sx={TableTrStyle(blockClass)} key={time}>
            <th>{time}</th>
            {sessions.map((c, i) => (
              <RenderSessions session={c} key={i} />
            ))}
          </Box>
        ))}
      </tbody>
    </Box>
  );
};

/**
 * セッション情報を表示します。
 *
 * @param {Object} session セッション情報、もしくはセッションがない場合は { key: … }
 * @param {Object} [session.sessionizeSession] Sessionize 形式のセッション情報
 * @param {number} [session.span] セルの高さ
 * @param {number} [session.key] セッションがない場合にタグの key 属性を渡します
 */
const RenderSessions: React.FC<{ session: TimetableCell }> = ({ session }) => {
  const { categories, rooms, speakers } = useModels();

  if (typeof session === "object") {
    const { sessionizeSession, startsAt, endsAt, span, height, sessionType } =
      session || {};
    const category = categories.of(sessionizeSession.categoryId);
    const room = rooms.name(sessionizeSession.room);
    const sps = sessionizeSession.speakerIds.map((s) => speakers.of(s).name);
    const sessionLang = sessionizeSession.language;
    const sessionSubLang = sessionizeSession.secondLanguage;
    const title = sessionizeSession.title;
    const { id, message } = sessionizeSession;

    const duration = toDuration(startsAt, endsAt);
    switch (sessionType) {
      case "welcome_talk":
        return (
          <Box component="td" sx={TableTdStyle(height)} key={id}>
            <SessionCard
              sessionLang="ja"
              sessionSubLang={room === "Online" ? undefined : "en"}
              title={title}
              duration={duration}
              room={room}
            />
          </Box>
        );
      case "after_party":
        return (
          <Box component="td" sx={TableTdStyle(height)} key={id}>
            <SessionCard
              title={`🎉 ${title} 🎉`}
              duration={duration}
              room="Exhibition"
            />
          </Box>
        );
      case "lunch":
        return (
          <Box
            component="td"
            sx={TableBreakStyle}
            key={id}
            colSpan={rooms.length}
          >
            <Headline color="textSecondary">
              🍱 {title.toUpperCase()} 🍱
              <br />
              {duration}min
            </Headline>
          </Box>
        );
      case "reserved":
        return (
          <Box component="td" sx={TableTdStyle(height)} key={id} rowSpan={span}>
            <SessionCard
              title={title}
              duration={duration}
              room={room}
              sessionLang={sessionLang}
              sessionSubLang={sessionSubLang}
              link={
                sessionizeSession.description ? `/timetable/${id}` : undefined
              }
              onClick={() => {
                sessionClicked = true;
              }}
            />
          </Box>
        );
      default:
        return (
          <Box component="td" sx={TableTdStyle(height)} key={id} rowSpan={span}>
            <SessionCard
              sessionLang={sessionLang}
              sessionSubLang={sessionSubLang}
              duration={duration}
              room={room}
              title={title}
              speaker={sps.join(", ")}
              topic={category.name}
              link={`/timetable/${id}`}
              message={message}
              onClick={() => {
                sessionClicked = true;
              }}
            />
          </Box>
        );
    }
  }

  return <Box component="td" sx={TabletdNoneIfMobileStyle} />;
};

const toDuration = (startsAt: string, endsAt: string): number => {
  return (
    (new Date(endsAt).getTime() - new Date(startsAt).getTime()) / (60 * 1000)
  );
};
