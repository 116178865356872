import React from "react";
import { main, Layout } from "helpers/MainLayout";
import MainContainer from "helpers/MainContainer";
import { makeText } from "helpers/i18n";
import Categories from "helpers/pages/cfs/categories";
import { CfsCard } from "helpers/pages/cfs/cfscard";
import { ExternalLink } from "helpers/Link";
import PastSubmissions from "helpers/pages/cfs/pastsubmissions";
import { Title, Subtitle, SubtitleProps } from "helpers/text";
import { Box, Button } from "@mui/material";

const St: React.FC<SubtitleProps> = (props) => (
  <Box mt={3}>
    <Subtitle {...props} />
  </Box>
);

// ----------------------------------------------------------------
//     テキスト
// ----------------------------------------------------------------

const proposalsUrl =
  "https://docs.google.com/spreadsheets/d/1yiuxY7BOO85UFPFlloIxKC0ikeV5eW_hkqP84SNV_rQ/edit?usp=sharing";
const sessionizeUrl = "https://sessionize.com/droidkaigi2020/";

const useText = makeText({
  ja: {
    content: (
      <React.Fragment>
        <p>
          DroidKaigiは、Android技術情報の共有とコミュニケーションを目的に開催する、エンジニアが主役のAndroidカンファレンスです。
          <br />
          DroidKaigi
          2019では、89件のセッションに1000人以上の開発者が参加しました。
        </p>
        <St>発表者の募集</St>
        <p>
          DroidKaigi 2020での発表者を募集します。
          次に挙げるカテゴリの中から1つを選択して、必要事項を記入の上、応募してください。
        </p>
      </React.Fragment>
    ),
    content2: (
      <React.Fragment>
        <St>旅費のサポート</St>
        <p>
          DroidKaigiでは本年度から正式に旅費をサポートしはじめました。予算に限りがあることから所属企業の旅費支援がない方に限らせていただいております。
          <br />
          今年も皆様の素晴らしい講演を楽しみにしています。
        </p>
        <St>要項</St>
        <p>
          セッションの時間は40分です。
          <br />
          応募先 <ExternalLink href={sessionizeUrl} />{" "}
          のフォームより発表のカテゴリ、タイトルとアブストラクト(最大1,500文字)等を送信してください。
          送信時に登録したアカウントを利用することで応募締め切りまでの間、自由に編集することが可能です。
          <br />
          応募いただいたタイトルやセッション用氏名等は採択、非採択にかかわらず応募状況ページにて公開されます。あらかじめご了承ください。
        </p>
        <p>応募に当たって以下の3点を満たしていると採択されやすくなります。</p>

        <Box mt={3}>
          <CfsCard>
            <h6>Android specific</h6>
            Android 固有であること
            <h6>Expertise</h6>
            よく知られている情報ではなく、専門性があること
            <h6>Innovation</h6>
            いままでに可能でなかったことを可能にしていること、革新性があること
          </CfsCard>
        </Box>
        <St>同時通訳</St>
        <p>
          一部のセッションを対象に当日の同時通訳を予定しています（日本語から英語,
          英語から日本語）。
          <br />
          応募先のフォームにて資料の事前提出に同意頂いたセッションのうち採択されたセッションの中から、同時通訳対象となるセッションを運営によって選定させて頂きます。
          <br />
          対象セッションに選ばれた場合、同時通訳の準備のために、開催2週間前(2020年2月8日頃)迄に登壇資料を提出頂くこととなります。詳細は12月中に連絡を差し上げます。
        </p>
        <p>
          同時通訳の試みは、グローバルとローカルをつなぐダイバーシティを考える上で大事な施策であると考えております。対象セッションに選ばれた皆様には大変なご負担をお掛けすることになりますが、何卒ご協力のほどお願い致します。
        </p>
        <St>投稿件数の上限</St>
        <p>
          1人あたり3件までのセッション投稿を認めます。セッション公募期限を過ぎて1人あたり4件以上のセッション投稿が残っていた場合、該当する応募者のすべてのセッションを落選として扱います。
          <br />
          複数人で登壇を希望する場合、セッションオーナー（セッションの主発表者）が応募数上限の3件を超えないように留意してください。
        </p>
        <p>
          また、セッション採択は原則として1人あたり1件となります。複数人が登壇するセッション等、考慮すべき事象が発生した場合はこの限りではありません。
        </p>
        <Box mt={3}>
          <CfsCard>
            <h6>応募期間</h6>
            2019/09/21 00:00 〜 2019/10/21 23:59
            <h6>結果発表予定日</h6>
            2019年11月中旬(予定)
            <h6>チケット</h6>
            採択時はチケット購入無しで参加が可能です
            <br />
            <strong>不採択時のチケットの取り置きはございません。</strong>
            <br />
            チケットの販売開始時にご購入ください。
            <br />
            チケット販売開始は11月下旬頃を予定しております。
            <h6>応募先</h6>
            <ExternalLink href={sessionizeUrl} />
          </CfsCard>
        </Box>
        <St>参考資料(過去の投稿一覧)</St>
      </React.Fragment>
    ),
    checkProposals: "応募状況を見る",
    submit: "応募する",
  },
  en: {
    content: (
      <React.Fragment>
        <p>
          DroidKaigi is a conference tailored for developers organized by
          developers. Its main focus is to provide developers a place to network
          and share knowledge with their peers.
          <br />
          DroidKaigi 2019 saw over 1000 developers with 89 sessions.
        </p>
        <St>Call for Speakers</St>
        <p>
          DroidKaigi 2020 is currently accepting speaker applications. To submit
          a talk, choose a category from the list below and fill out the
          necessary information.
        </p>
      </React.Fragment>
    ),
    content2: (
      <React.Fragment>
        <St>Travel Expenses Support</St>
        <p>
          This year, DroidKaigi will officially provide support for travel
          expenses. Speakers who do not have travel support from a company they
          belong to can apply. We look forward to your awesome talks!
        </p>
        <St>Submission Details</St>
        <p>
          Sessions are 40 minutes long.
          <br />
          Submit the topic, title, and abstract (1,500 characters max) of your
          talk from here ( <ExternalLink href={sessionizeUrl} /> ). You may edit
          your application until the deadline with the account used for
          submission.
          <br />
          The title, abstract, name of the speaker, and other details of
          applications will be published regardless of their result.
        </p>
        <p>Applications are more likely to be accepted if they are:</p>
        <Box mt={3}>
          <CfsCard>
            <h6>Android specific</h6>
            <h6>Involves an area of expertise</h6>
            Not about a subject widely known
            <h6>Innovative</h6>
            About a topic that demonstrates something thought to be impossible
            or very hard to achieve
          </CfsCard>
        </Box>
        <St>Simultaneous Interpretation</St>
        <p>
          We are planning to provide simultaneous interpretation for select
          sessions (Both Japanese to English and English to Japanese).
          <br />
          Sessions will be selected from those who have agreed to submit
          material early.
          <br />
          If your session is chosen to be interpreted, we ask that you submit
          your talk material approximately two weeks (around Feb 8th, 2020)
          before the conference. More information will be provided in December.
        </p>
        <p>
          We believe that this initiative is significant in providing a more
          diverse conference, bringing both local and global attendees together
          as one community. Though we do understand that early preparation will
          be a heavy burden for those selected, we kindly ask for your
          understanding.
        </p>
        <St>Submission Limits</St>
        <p>
          Each speaker may submit up to three talks. If a speaker has four or
          more submissions at the time of the deadline, all submissions by the
          speaker will be rejected.
          <br />
          If your talk has multiple speakers, be sure that the submission count
          for the owner (the submitter of the talk) does not exceed the
          submission limit.
        </p>
        <p>
          As a general rule, no more than one session per speaker will be
          selected. This may not apply in cases where special consideration is
          needed (e.g. sessions with multiple speakers).
        </p>
        <Box mt={3}>
          <CfsCard>
            <h6>Submission Period</h6>
            Sep 21, 2019 00:00 JST - Oct 21, 2019 23:59 JST
            <h6>Result Announcement</h6>
            Mid-November 2019 (Tentative)
            <h6>Tickets</h6>
            If selected, you will not need a ticket for admission.
            <br />
            <strong>
              There are no reserved tickets for those not selected.
            </strong>
            <br />
            Please purchase your ticket when they go on sale (Scheduled late
            November).
            <h6>Submission Form</h6>
            <ExternalLink href={sessionizeUrl} />
          </CfsCard>
        </Box>
        <St>Previous applications can be viewed here</St>
      </React.Fragment>
    ),
    checkProposals: "Check Talk Stats",
    submit: "Submit",
  },
});

// ----------------------------------------------------------------
//     全体構成
// ----------------------------------------------------------------

const useCfsStyle = (): Record<string, unknown> => ({});
/*
const useCfsStyle = makeStyles((theme) => ({
  textRoot: {
    "& a": {
      color: theme.palette.primary.main,
    },
    "& p": {
      marginTop: theme.spacing(0.5),
    },
  },
  button: {
    color: theme.palette.primary.contrastText,
    height: theme.spacing(6),
  },
}));
*/

const CfsMain: React.FC = () => {
  const text = useText();
  const classNames = useCfsStyle();
  return (
    <Layout title="Call for Speakers">
      <Box my={5}>
        <MainContainer>
          <div className={`${classNames.textRoot}`}>
            <Title>Call for Speakers</Title>
            {text.content}
            <Categories />
            {text.content2}
            <PastSubmissions />
          </div>
          <Box mt={5} marginX="auto" maxWidth="480px">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              component={ExternalLink}
              href={proposalsUrl}
              className={`${classNames.button}`}
            >
              {text.checkProposals}
            </Button>
          </Box>
          <Box mt={2} marginX="auto" maxWidth="480px">
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              disabled
              component={ExternalLink}
              href={sessionizeUrl}
              className={`${classNames.button}`}
            >
              {text.submit}
            </Button>
          </Box>
        </MainContainer>
      </Box>
    </Layout>
  );
};

export default main(CfsMain);
