import React from "react";
import Linkify from "react-linkify";
import { CategoryLabel, DialogLang } from "./Labels";
import { useModels } from "./Models";
import { Session } from "helpers/timetable";
import { makeText } from "helpers/i18n";
import { ExternalLink } from "helpers/Link";
import { Headline, Body } from "helpers/text";
import {
  Box,
  Button,
  Dialog,
  DialogProps,
  Hidden,
  IconButton,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close, InsertDriveFile, Link, PlayArrow } from "@mui/icons-material";
import { Theme } from "@mui/system";

// ----------------------------------------------------------------
//     ローカライズ
// ----------------------------------------------------------------

const useText = makeText({
  en: {
    video: "Watch Video",
    slide: "View Slide",
  },
  ja: {
    video: "動画を見る",
    slide: "スライドを見る",
  },
});

// ----------------------------------------------------------------
//     スタイル
// ----------------------------------------------------------------

const contentsStyle: SxProps<Theme> = ({ spacing }) => ({
  padding: { xs: `0 ${spacing(2)} ${spacing(2)}`, sm: spacing(4) },
});

const bodyStyle: SxProps<Theme> = {
  marginTop: 4,
  whiteSpace: "pre-wrap",
  typography: "body1",

  "& a": {
    color: "primary.main",
  },
};

const ExternalDocLinkComponent: React.FC<{ children?: React.ReactNode }> = ({
  children,
  ...props
}) => {
  const attrs = { rel: "noreferrer noopener", target: "_blank", ...props };
  return <a {...attrs}>{children}</a>;
};

export const DocLink: React.FC<{
  href: string;
  external: boolean;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
}> = ({ href, external, sx, children }) => {
  return (
    <Button
      variant="contained"
      LinkComponent={external ? ExternalDocLinkComponent : "a"}
      href={href}
      disableElevation={true}
      sx={[
        ({ palette, typography, breakpoints }) => ({
          minHeight: 40,
          minWidth: 160,
          borderRadius: 20,
          px: 3,
          [breakpoints.only("xs")]: {
            width: "100%",
          },

          background: palette.background.paper,
          "&:hover": {
            background: palette.background.default,
          },

          color: palette.text.primary,
          fontSize: typography.body1.fontSize,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Button>
  );
};

const MobileDialog: React.FC<DialogProps> = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return <Dialog fullScreen={fullScreen} {...props} />;
};

// ----------------------------------------------------------------
//     レイアウト
// ----------------------------------------------------------------

type DetailDialogProps = {
  sessionizeSession: Session | undefined;
  onExit?: () => void;
};

export const DetailDialog: React.FC<DetailDialogProps> = ({
  sessionizeSession,
  onExit,
}) => {
  const text = useText();
  const { categories, rooms, speakers } = useModels();

  if (!sessionizeSession) return <Dialog open={false}>&nbsp;</Dialog>;

  const category = categories.of(sessionizeSession.categoryId);
  const room = rooms.name(sessionizeSession.room);
  const lang = sessionizeSession.language;
  const sublang = sessionizeSession.secondLanguage;
  const time = timeToLongTextFormat(
    sessionizeSession.startsAt,
    sessionizeSession.endsAt
  );
  const speakersDetail = sessionizeSession.speakerIds.map((s) =>
    speakers.of(s)
  );
  const msg = sessionizeSession.message;
  const title = sessionizeSession.title;
  const videoUrl = sessionizeSession.asset.videoUrl;
  const slideUrl = sessionizeSession.asset.slideUrl;

  return (
    <MobileDialog open maxWidth="md" onBackdropClick={onExit}>
      <Box sx={{ overflowY: "scroll" }}>
        <Hidden smUp>
          <Box mx={1} mt={1}>
            <IconButton aria-label="Close" onClick={onExit}>
              <Close />
            </IconButton>
          </Box>
        </Hidden>
        <Box sx={[contentsStyle]}>
          <Typography variant="h3" style={{ lineHeight: 1.4 }}>
            {title}
          </Typography>
          <div>
            {lang && <DialogLang lang={lang} />}
            {
              " " /* react は二つの要素の間スペースが消去されるらしい…回避策としてスペースをあえて付与 */
            }
            {sublang && <DialogLang lang={sublang} interpretation />}
            {
              " " /* react は二つの要素の間スペースが消去されるらしい…回避策としてスペースをあえて付与 */
            }
            <Headline color="textSecondary" component={"span"}>
              {`${room} － ${time}`}
            </Headline>
          </div>
          <Box mt={2}>
            {category.name && <CategoryLabel>{category.name}</CategoryLabel>}
          </Box>
          {(() => {
            if (videoUrl || slideUrl) {
              return (
                <Box mt={2}>
                  {videoUrl && (
                    <DocLink href={videoUrl} external>
                      <PlayArrow />
                      &nbsp;&nbsp;&nbsp;
                      {text.video}
                    </DocLink>
                  )}
                  {slideUrl && (
                    <DocLink
                      href={slideUrl}
                      external
                      sx={({ breakpoints }) => ({
                        [breakpoints.only("xs")]: {
                          "&:not(:first-child)": { marginTop: 2 },
                        },
                        [breakpoints.up("sm")]: {
                          "&:not(:first-child)": { marginLeft: 2 },
                        },
                      })}
                    >
                      <InsertDriveFile />
                      &nbsp;&nbsp;&nbsp;
                      {text.slide}
                    </DocLink>
                  )}
                </Box>
              );
            }
          })()}
          {msg && (
            <Box mt={2} color="error.main" whiteSpace="pre-wrap">
              {msg}
            </Box>
          )}
          <Box sx={bodyStyle}>
            <AutoLink text={sessionizeSession.description} />
          </Box>

          {speakersDetail.map((s, i) => (
            <Box mt={5} style={{ display: "flex" }} key={i}>
              <Box
                flex={{ xs: "0 0 96px", sm: "0 0 128px" }}
                height={{ xs: "96px", sm: "128px" }}
                borderRadius="50%"
                bgcolor="background.default"
              >
                {s.profilePicture && (
                  <img
                    style={{ width: "100%", borderRadius: "50%" }}
                    src={s.profilePicture}
                    alt={s.name}
                  />
                )}
              </Box>
              <Box sx={[contentsStyle]}>
                <Headline>{s.name}</Headline>
                <Body style={{ fontStyle: "italic" }}>{s.tagLine}</Body>
                <Box mt={1} color="secondary.main">
                  {s.links.length ? <Link /> : []}
                  {s.links.map((l) => (
                    <Box
                      component={ExternalLink}
                      key={l.url}
                      href={l.url}
                      mr={2}
                      sx={{
                        display: "inline-block",
                        verticalAlign: "middle",
                      }}
                    >
                      {l.title}
                    </Box>
                  ))}
                </Box>
                <Box sx={bodyStyle}>
                  <AutoLink text={s.bio} />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </MobileDialog>
  );
};

const AutoLink: React.FC<{ text: string }> = ({ text }) => (
  <Linkify
    componentDecorator={(href, text, key) => (
      <ExternalLink key={key} href={href}>
        {text}
      </ExternalLink>
    )}
  >
    {text}
  </Linkify>
);

const timeToLongTextFormat = (start: string, end: string): string => {
  const s = /(\d+)-(\d+)-(\d+)T(\d+):(\d+)/.exec(start);
  const e = /(\d+)-(\d+)-(\d+)T(\d+):(\d+)/.exec(end);
  if (s && e) {
    return `${s[1]}/${s[2]}/${s[3]} ${s[4]}:${s[5]}-${e[4]}:${e[5]}`;
  } else {
    return `${start} - ${end}`;
  }
};
