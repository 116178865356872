import React from "react";
import { makeText } from "helpers/i18n";
import { Body } from "helpers/text";
import { Box } from "@mui/material";

const useText = makeText({
  en: {
    interpretation: "(Simultaneous Interpretation)",
  },
  ja: {
    interpretation: "(同時通訳)",
  },
});

type LangAttributes = { lang: "ja" | "en" | "mixed" };

const labelColor = (lang: LangAttributes["lang"]): string => {
  return lang === "en"
    ? "primary.main"
    : lang === "mixed"
    ? "#825DC7"
    : "secondary.main";
};

export const LangLabel: React.FC<LangAttributes> = ({ lang, ...props }) => {
  return (
    <Box
      component="span"
      bgcolor={labelColor(lang)}
      p="0 4px"
      fontSize="caption.fontSize"
      fontWeight="500"
      color="#fff"
      sx={{ float: "left" }}
      {...props}
    >
      {lang.toUpperCase()}
    </Box>
  );
};

export const DialogLang: React.FC<
  LangAttributes & {
    interpretation?: boolean;
  }
> = ({ interpretation, lang, ...props }) => {
  const text = useText();
  return (
    <Body
      component="span"
      bgcolor={labelColor(lang)}
      p="2px 4px"
      color="#fff"
      {...props}
    >
      {lang.toUpperCase()}
      {interpretation && text.interpretation}
    </Body>
  );
};

export const CategoryLabel: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box
      component="span"
      bgcolor="timetableBorder"
      mr="0.5ex"
      p="0.25ex 0.5ex"
      borderRadius="4px"
      lineHeight="1.2"
      fontSize={({ breakpoints }) => ({ [breakpoints.only("xs")]: "90%" })}
    >
      {children}
    </Box>
  );
};
