import React from "react";

import Main from "../helpers/pages/timetable/Main";
import { Layout, main } from "helpers/MainLayout";
import { LangType, I18nContextProvider, useLang, makeText } from "helpers/i18n";
import { GlobalThemeProviderHead } from "helpers/theme";
import Metadata from "helpers/MainLayout/Metadata";

const useText = makeText({
  en: { title: "Timetable" },
  ja: { title: "タイムテーブル" },
});

const TimetableContent: React.FC<{
  sessionId: string;
  title: Record<LangType, string> | undefined;
  description: string | undefined;
  speakers: string[] | undefined;
}> = ({ sessionId }) => {
  return (
    <Layout>
      <Main sessionId={typeof sessionId === "string" ? sessionId : ""} />
    </Layout>
  );
};

const makeOgImage = (localTitle: string, speakers: string[]): string => {
  const t = encodeURIComponent(localTitle);
  const s = encodeURIComponent(speakers.join("%2C "));
  return `https://res.cloudinary.com/wm3/image/upload/c_fit,co_rgb:ffffff,l_text:Sawarabi%20Gothic_52_bold:${t},w_932/fl_layer_apply,g_center,y_-40/co_rgb:48D597,l_text:Sawarabi%20Gothic_36_normal:${s}/fl_layer_apply,g_south_west,x_134,y_157/droidkaigi2022/session-ogp-bg_fhpq5x.png`;
};

const TimetableMain = main(TimetableContent);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TimetablePage: React.FC<{ pageContext: any }> = ({ pageContext }) => {
  const { sessionId, title, description, speakers } = pageContext;
  return (
    <TimetableMain
      sessionId={typeof sessionId === "string" ? sessionId : ""}
      title={title}
      description={description}
      speakers={speakers}
    />
  );
};

export default TimetablePage;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Head: React.FC<{ pageContext: any }> = ({ pageContext }) => {
  const { title, description, speakers } = pageContext;
  return (
    <I18nContextProvider>
      <HeadMain title={title} description={description} speakers={speakers} />
    </I18nContextProvider>
  );
};

const HeadMain: React.FC<{
  title: Record<LangType, string> | undefined;
  description: string | undefined;
  speakers: string[] | undefined;
}> = ({ title, description, speakers }) => {
  const lang = useLang();
  const text = useText();
  const localTitle = title ? title[lang] : text.title;

  const ogImage =
    localTitle && speakers ? makeOgImage(localTitle, speakers) : undefined;

  return (
    <>
      <Metadata
        title={localTitle}
        description={description}
        ogImage={ogImage}
      />
      <GlobalThemeProviderHead />
    </>
  );
};
