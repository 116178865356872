import React from "react";
import { LangLabel, CategoryLabel } from "./Labels";
import Link from "helpers/Link";
import { Subheadline, Body, Caption } from "helpers/text";
import { Box, Paper } from "@mui/material";

// ----------------------------------------------------------------
//     レイアウト/タイムテーブルの項目
// ----------------------------------------------------------------

type SesscionCardProps = {
  title: string;
  speaker?: string;
  duration: number;
  room: string;
  sessionLang?: "en" | "ja" | "mixed";
  sessionSubLang?: "en" | "ja";
  topic?: string;
  link?: string;
  message?: string;
  onClick?: () => void;
};

/**
 * セッションをテーブルに描画します
 */
const SessionCardMain: React.FC<SesscionCardProps> = ({
  title,
  speaker,
  duration,
  room,
  sessionLang,
  sessionSubLang,
  topic,
  link,
  message,
  onClick,
}) => {
  const topContent = {
    marginLeft: 1,
    marginRight: 1,
  };
  const content = (
    <React.Fragment>
      <Box
        component="div"
        sx={{
          ...topContent,
          marginLeft: 0,
          lineHeight: "20px",
          textAlign: "right",
          xs: {
            marginLeft: "0px",
            textAlign: "left",
          },
        }}
      >
        {sessionLang ? <LangLabel lang={sessionLang} /> : []}
        {sessionSubLang ? <LangLabel lang={sessionSubLang} /> : []}
        <Box
          component="span"
          sx={{
            color: "text.secondary",
            verticalAlign: "top",
            fontSize: "caption.fontSize",
            xs: {
              // fontSize: ${props => props.theme.fonts.normal};
              textAlign: "left",
              paddingLeft: "8px",
            },
          }}
        >
          {room} / {duration}min
        </Box>
      </Box>
      <Subheadline
        sx={{
          ...topContent,
          marginTop: 1,

          "& a": {
            color: "inherit",
          },
        }}
      >
        {title}
      </Subheadline>
      <Box mt={1}>
        <Body color="textSecondary" sx={{ ...topContent }}>
          {speaker}
        </Body>
      </Box>
      <Box mt={1} lineHeight={2} sx={{ ...topContent }}>
        {topic ? (
          <CategoryLabel>
            <Caption>{topic}</Caption>
          </CategoryLabel>
        ) : (
          []
        )}
      </Box>
      {(() => {
        if (!message) return <React.Fragment />;
        return (
          <Body
            color="error.main"
            sx={{
              ...topContent,
              marginTop: "0.25em",
              marginBottom: "0.25em",
            }}
          >
            {message}
          </Body>
        );
      })()}
    </React.Fragment>
  );

  return (
    <Paper
      sx={{
        height: "100%",
        overflow: "hidden",
        xs: {
          paddingBottom: 1,
        },
        "&.timetable-lang": {
          borderTopLeftRadius: "8px",
        },
      }}
    >
      {link ? (
        // xeslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <Link
          style={{
            display: "block",
            height: "100%",
          }}
          to={link}
        >
          <div style={{ height: "100%" }} onClick={onClick}>
            {content}
          </div>
        </Link> // TODO スクロール調整
      ) : (
        content
      )}
    </Paper>
  );
};

export default SessionCardMain;
